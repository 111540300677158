interface DecodedToken {
    exp?: number;
  }

  export const isTokenExpired = (token: string): boolean => {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        atob(base64).split('').map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join('')
      );
      const decoded: DecodedToken = JSON.parse(jsonPayload);

      if (!decoded.exp) return true;

    // Add 10 second buffer before expiration
    return decoded.exp * 1000 <= Date.now() + 30000;
    } catch {
      return true;
    }
  };