import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaSpinner,  FaDownload  } from 'react-icons/fa';
import { useAuth } from '../context/AuthContext';
import { NavbarConfig } from '../App';
import { handleCSVDownload } from '../utils/csvDownload';
import axios from 'axios'
import {API_BASE_URL} from '../config/api'
import {UserIdsResponse} from '../services/auth'

interface NavbarProps {
  config: NavbarConfig;
  onLoadAllStudies?: () => void;
  isLoadingAll?: boolean;
}

export const Navbar: React.FC<NavbarProps> = ({
  config,
  onLoadAllStudies,
  isLoadingAll = false
}) => {
  console.log('Navbar config:', config);
  const { isAuthenticated, logout, token, refreshToken, userId, isAdmin, adminUserId} = useAuth();

  const navigate = useNavigate();

  // Add this function to handle switching users
  const handleSwitchUser = async () => {
    if (!token || !adminUserId) return;
    try {
      // Fetch available user IDs again
      const response = await axios.post<UserIdsResponse>(
        `${API_BASE_URL}/get_user_ids`,
        { user_id: adminUserId },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
            "Ocp-Apim-Subscription-Key": "replace_with_subscription_key"
          }
        }
      );

      // Navigate to UserSelector with the token and available IDs
      navigate('/login', {
        state: {
          token,
          refreshToken,  // Add this line
          availableUserIds: response.data.result,
          adminUserId,
          fromSwitch: true,
          isAuthenticated: true
        },
        replace: true
      });
    } catch (error) {
      console.error('Error fetching user IDs:', error);
    }
  };

  const triggerCSVDownload = async () => {
    if (!token || !userId) return;

    try {
      await handleCSVDownload(userId, token);
    } catch (error) {
      console.error('Error downloading CSV:', error);
    }
  };

  return (
    <div className="py-3 px-6 bg-gray-900/95 backdrop-blur-md border-b border-gray-800/50 sticky top-0 z-50 shadow-lg shadow-black/10">
      <div className="flex items-center justify-between max-w-7xl mx-auto">
        {/* Left section */}
        <div className="flex items-center space-x-6">
          <div className="flex items-center space-x-6">
            <Link
              to="/"
              className="text-xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-primary via-accent to-primary"
            >
              a2z Radiology AI
            </Link>
            {config.title && (
              <>
                <div className="h-4 w-px bg-gray-700/50"></div>
                <div className="flex items-center space-x-4">
                  {config.title === 'Viewer' && (
                    <button
                      onClick={() => navigate('/worklist')}
                      className="text-sm font-medium text-gray-400 hover:text-gray-200 transition-colors duration-200"
                    >
                      Worklist
                    </button>
                  )}
                  <span className="text-sm font-medium text-gray-300 tracking-wide">
                    {config.title}
                  </span>
                </div>
              </>
            )}
          </div>
        </div>

        {/* Right section */}
        <div className="flex items-center space-x-4">
          {isAuthenticated && (
            <>
              {!isAdmin && (
                <Link
                  to="/change-password"
                  className="px-4 py-1.5 rounded-md text-xs font-medium
                    bg-gray-800/80 hover:bg-gray-700/80
                    text-gray-300 hover:text-white
                    transition-all duration-200
                    border border-gray-700/50 hover:border-gray-600/50
                    shadow-sm hover:shadow-md shadow-black/5"
                >
                  Change Password
                </Link>
              )}
              {isAdmin && (
                <button
                  onClick={triggerCSVDownload}
                  className="px-4 py-1.5 rounded-md text-xs font-medium
                    bg-gray-800/80 hover:bg-gray-700/80
                    text-gray-300 hover:text-white
                    transition-all duration-200
                    border border-gray-700/50 hover:border-gray-600/50
                    shadow-sm hover:shadow-md shadow-black/5"
                >
                  Download CSV
                </button>
              )}
              {isAdmin && config.onLoadAllStudies && (
                <button
                  onClick={config.onLoadAllStudies}
                  disabled={config.isLoadingAll}
                  className="px-4 py-1.5 rounded-md text-xs font-medium
                    bg-gray-800/80 hover:bg-gray-700/80
                    text-gray-300 hover:text-white
                    transition-all duration-200
                    border border-gray-700/50 hover:border-gray-600/50
                    shadow-sm hover:shadow-md shadow-black/5
                    disabled:opacity-50 disabled:cursor-not-allowed
                    flex items-center gap-2"
                >
                  {config.isLoadingAll ? (
                    <>
                      <FaSpinner className="w-3 h-3 animate-spin" />
                      <span>Loading...</span>
                    </>
                  ) : (
                    <>
                      <FaDownload className="w-3 h-3" />
                      <span>Load All Studies</span>
                    </>
                  )}
                </button>
              )}
              {isAdmin && (
                <button
                  onClick={handleSwitchUser}
                  className="px-4 py-1.5 rounded-md text-xs font-medium
                    bg-gray-800/80 hover:bg-gray-700/80
                    text-gray-300 hover:text-white
                    transition-all duration-200
                    border border-gray-700/50 hover:border-gray-600/50
                    shadow-sm hover:shadow-md shadow-black/5"
                >
                  Switch User
                </button>
              )}
              {isAdmin && (
                <button
                  onClick={() => navigate('/edit-report-prompt')}
                  className="px-4 py-1.5 rounded-md text-xs font-medium
                    bg-gray-800/80 hover:bg-gray-700/80
                    text-gray-300 hover:text-white
                    transition-all duration-200
                    border border-gray-700/50 hover:border-gray-600/50
                    shadow-sm hover:shadow-md shadow-black/5"
                >
                  Edit Prompt
                </button>
              )}
              {isAdmin && (
                <button
                  onClick={() => navigate('/migrate-files', {
                    state: {
                      token,
                      adminUserId,
                      fromSwitch: true
                    }
                  })}
                  className="px-4 py-1.5 rounded-md text-xs font-medium
                    bg-gray-800/80 hover:bg-gray-700/80
                    text-gray-300 hover:text-white
                    transition-all duration-200
                    border border-gray-700/50 hover:border-gray-600/50
                    shadow-sm hover:shadow-md shadow-black/5"
                >
                  Copy Files
                </button>
              )}
              <button
                onClick={logout}
                className="px-4 py-1.5 rounded-md text-xs font-medium
                  bg-gray-800/80 hover:bg-gray-700/80
                  text-gray-300 hover:text-white
                  transition-all duration-200
                  border border-gray-700/50 hover:border-gray-600/50
                  shadow-sm hover:shadow-md shadow-black/5"
              >
                Sign Out
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};