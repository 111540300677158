import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import { API_BASE_URL } from '../config/api';

interface LocationState {
  token?: string;
  adminUserId?: string;
  fromSwitch?: boolean;
}

const MigrateFiles: React.FC = () => {
  const [formData, setFormData] = useState({
    src_user_id: '',
    dst_user_id: '',
    study_ids: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const auth = useAuth();
  const { token: authToken, userId } = auth;
  const location = useLocation() as { state: LocationState };
  const { token: routeToken, adminUserId, fromSwitch } = location.state || {};

  useEffect(() => {
    if (!auth.isAuthenticated) {
      if (fromSwitch && routeToken && adminUserId) {
        // If coming from switch user, update auth state
        auth.setAuthState({
          isAuthenticated: true,
          token: routeToken,
          userId: adminUserId,
          isAdmin: true,
          adminUserId
        });
      } else {
        // Only navigate to login if not coming from switch
        navigate('/login');
      }
    }
  }, [auth, routeToken, adminUserId, fromSwitch, navigate]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    try {
      // Convert comma-separated study_ids to array
      const studyIdsList = formData.study_ids
        .split(',')
        .map(id => id.trim())
        .filter(id => id.length > 0);

      const response = await axios.post(
        `${API_BASE_URL}/migrate_study_ids`,
        {
          user_id: adminUserId || userId,
          src_user_id: formData.src_user_id,
          dst_user_id: formData.dst_user_id,
          study_ids: studyIdsList
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${routeToken}`,
            "Ocp-Apim-Subscription-Key": "replace_with_subscription_key"
          }
        }
      );

      if (response.data.status === 200) {
        navigate('/worklist');
      } else {
        setError(response.data.message);
      }
    } catch (error: any) {
      setError(error.response?.data?.message || 'Failed to migrate files. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex min-h-screen bg-gray-900 text-white">
      <div className="flex-1 flex items-center justify-center p-4">
        <form
          onSubmit={handleSubmit}
          className="w-full max-w-md bg-gray-800/50 backdrop-blur-md border border-gray-700/50 rounded-lg p-8 shadow-2xl"
        >
          <div className="text-center mb-8">
            <span className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-primary to-accent">
              a2z
            </span>
            <h2 className="mt-4 text-xl font-medium text-gray-300">Copy Files</h2>
          </div>

          {error && (
            <div className="mb-4 p-3 bg-red-500/20 border border-red-500/50 rounded-lg text-red-200 text-sm">
              {error}
            </div>
          )}

          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-300 mb-1">
                Source User ID
              </label>
              <input
                type="text"
                name="src_user_id"
                value={formData.src_user_id}
                onChange={handleInputChange}
                className="w-full bg-gray-700/50 border border-gray-600 rounded-lg px-3 py-2 text-white"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-300 mb-1">
                Destination User ID
              </label>
              <input
                type="text"
                name="dst_user_id"
                value={formData.dst_user_id}
                onChange={handleInputChange}
                className="w-full bg-gray-700/50 border border-gray-600 rounded-lg px-3 py-2 text-white"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-300 mb-1">
                Study IDs (comma-separated)
              </label>
              <input
                type="text"
                name="study_ids"
                value={formData.study_ids}
                onChange={handleInputChange}
                className="w-full bg-gray-700/50 border border-gray-600 rounded-lg px-3 py-2 text-white"
                required
                placeholder="id1, id2, id3"
              />
            </div>

            <button
              type="submit"
              disabled={isLoading}
              className="w-full bg-primary hover:bg-primary-dark disabled:bg-gray-600
                text-white font-medium py-2 px-4 rounded-lg transition-colors duration-200"
            >
              {isLoading ? 'Processing...' : 'Copy Files'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default MigrateFiles;