import axios from 'axios';
import { API_BASE_URL } from '../config/api';
import Cookies from 'js-cookie';
const TOKEN_COOKIE_NAME = 'a2z_auth_token';
const REFRESH_TOKEN_COOKIE_NAME = 'a2z_refresh_token';
const USER_ID_COOKIE_NAME = 'a2z_user_id';
const IS_ADMIN_COOKIE_NAME = 'a2z_is_admin';
const ADMIN_USER_ID_COOKIE_NAME = 'a2z_admin_user_id';
const COOKIE_EXPIRY_DAYS = 7;

interface LoginResponse {
  access_token: string;
  refresh_token: string;
  token_type: string;
}

export interface UserIdsResponse {
  result: string[];
}

export const authService = {
  async login(userId: string, password: string): Promise<{
    token: string;
    refreshToken: string;
    availableUserIds: string[];
  }> {
    // First login attempt
    const response = await axios.post<LoginResponse>(
      `${API_BASE_URL}/api/auth/login`,
      {
        user_id: userId,
        password: password
      },
      {
        headers: {
          "Content-Type": "application/json",
          "Ocp-Apim-Subscription-Key": "replace_with_subscription_key"
        }
      }
    );

    const accessToken = response.data.access_token;
    const refreshToken = response.data.refresh_token;

    // Fetch available user IDs
    const userIdsResponse = await axios.post<UserIdsResponse>(
      `${API_BASE_URL}/get_user_ids`,
      {
        user_id: userId
      },
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${accessToken}`,
          "Ocp-Apim-Subscription-Key": "replace_with_subscription_key"
        }
      }
    );

    const availableUserIds = userIdsResponse.data.result;

    // If there's only one user ID, set the cookies immediately
    if (availableUserIds.length === 1) {
      this.setAuthCookies(accessToken, refreshToken, availableUserIds[0], false);
    }

    return {
      token: accessToken,
      refreshToken: refreshToken,
      availableUserIds
    };
  },

  // Updated method to handle both tokens
  setAuthCookies(
    accessToken: string,
    refreshToken: string,
    userId: string,
    isAdmin: boolean,
    adminUserId?: string
  ): void {
    // Set access token with short expiry
    Cookies.set(TOKEN_COOKIE_NAME, accessToken, {
      expires: 1, // 1 day
      secure: true,
      sameSite: 'strict',
      path: '/'
    });

    // Set refresh token with longer expiry
    Cookies.set(REFRESH_TOKEN_COOKIE_NAME, refreshToken, {
      expires: COOKIE_EXPIRY_DAYS,
      secure: true,
      sameSite: 'strict',
      path: '/'
    });

    // Set other cookies as before
    Cookies.set(USER_ID_COOKIE_NAME, userId, {
      expires: COOKIE_EXPIRY_DAYS,
      secure: true,
      sameSite: 'strict',
      path: '/'
    });

    Cookies.set(IS_ADMIN_COOKIE_NAME, String(isAdmin), {
      expires: COOKIE_EXPIRY_DAYS,
      secure: true,
      sameSite: 'strict',
      path: '/'
    });

    if (isAdmin && adminUserId) {
      Cookies.set(ADMIN_USER_ID_COOKIE_NAME, adminUserId, {
        expires: COOKIE_EXPIRY_DAYS,
        secure: true,
        sameSite: 'strict',
        path: '/'
      });
    }
  },

  async refreshToken(): Promise<string | null> {
    const refreshToken = Cookies.get(REFRESH_TOKEN_COOKIE_NAME);
    const userId = Cookies.get(USER_ID_COOKIE_NAME);
    const isAdmin = Cookies.get(IS_ADMIN_COOKIE_NAME) === 'true';
    const adminUserId = Cookies.get(ADMIN_USER_ID_COOKIE_NAME);

    // If no refresh token, can't refresh
    if (!refreshToken || !userId) {
      return null;
    }

    try {
      // Use adminUserId for refresh if in admin mode, otherwise use regular userId
      const userIdForRefresh = isAdmin && adminUserId ? adminUserId : userId;

      const response = await axios.post<LoginResponse>(
        `${API_BASE_URL}/api/auth/refresh`,
        {
          refresh_token: refreshToken,
          user_id: userIdForRefresh
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Ocp-Apim-Subscription-Key": "replace_with_subscription_key"
          }
        }
      );

      const newAccessToken = response.data.access_token;
      const newRefreshToken = response.data.refresh_token;

      // Update both tokens
      Cookies.set(TOKEN_COOKIE_NAME, newAccessToken, {
        expires: 1/2, // 30 minute in days
        secure: true,
        sameSite: 'strict',
        path: '/'
      });

      Cookies.set(REFRESH_TOKEN_COOKIE_NAME, newRefreshToken, {
        expires: 7, // 7 days
        secure: true,
        sameSite: 'strict',
        path: '/'
      });

      return newAccessToken;
    } catch (error) {
      // If refresh fails, clear all auth cookies
      this.logout(userId);
      return null;
    }
  },

  logout: async (userId: string) => {
    try {
      // Clear all cookies including refresh token
      Cookies.remove(TOKEN_COOKIE_NAME, { path: '/' });
      Cookies.remove(REFRESH_TOKEN_COOKIE_NAME, { path: '/' });
      Cookies.remove(USER_ID_COOKIE_NAME, { path: '/' });
      Cookies.remove(IS_ADMIN_COOKIE_NAME, { path: '/' });
      Cookies.remove(ADMIN_USER_ID_COOKIE_NAME, { path: '/' });
    } catch (error) {
      console.error('Error during logout cleanup:', error);
    }
  },

  // Updated to include refresh token in auth check
  isLoggedIn(): boolean {
    const accessToken = Cookies.get(TOKEN_COOKIE_NAME);
    const refreshToken = Cookies.get(REFRESH_TOKEN_COOKIE_NAME);
    const userId = Cookies.get(USER_ID_COOKIE_NAME);
    return Boolean(accessToken && refreshToken && userId);
  },


  // Updated to include refresh token in stored auth data
  getStoredAuth(): {
    token: string | null;
    refreshToken: string | null;
    userId: string | null;
    isAdmin: boolean;
    adminUserId: string | null
  } {
    return {
      token: Cookies.get(TOKEN_COOKIE_NAME) || null,
      refreshToken: Cookies.get(REFRESH_TOKEN_COOKIE_NAME) || null,
      userId: Cookies.get(USER_ID_COOKIE_NAME) || null,
      isAdmin: Cookies.get(IS_ADMIN_COOKIE_NAME) === 'true',
      adminUserId: Cookies.get(ADMIN_USER_ID_COOKIE_NAME) || null
    };
  }
};