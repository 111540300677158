import React, { useState, useRef, useEffect } from 'react';
import { FaCheck, FaSpinner, FaRedo, FaChevronRight, FaTimes, FaLock, FaPaperPlane, FaEllipsisV, FaInfoCircle } from 'react-icons/fa';
import axios from 'axios';
import { API_BASE_URL } from '../config/api';
import { useAuth } from '../context/AuthContext';
import { formatOrganName, formatFindingName, formatConditionDisplay } from '../utils/textFormatters';
import {
  StructuredReport,
  SpatialGroup,
  GroupedFinding,
} from '../utils/reportGenerator';
import { ViewerMetrics, FeedbackRecord } from './types';

interface PredictionsDisplayProps {
  studyId: string;
  feedback?: Record<string, [string, number, string]>;
  onConditionSelect?: (
    condition: string | null,
    modelType: 'base' | 'experimental' | null
  ) => void;
  setViewerMetrics: React.Dispatch<React.SetStateAction<ViewerMetrics>>;
  viewerMetrics: ViewerMetrics;
  report: StructuredReport | null;
  isGeneratingReport: boolean;
  onRegenerateReport?: () => Promise<void>;
  baseModelPredictions: Record<string, any>;
  experimentalModelPredictions: Record<string, any>;
  spatialGroups: SpatialGroup[];
  setCurrentSlice: React.Dispatch<React.SetStateAction<number>>;
  totalSlices: number;
  onGroupSelect?: (groupId: string | null) => void;
  activeGroupId?: string | null;
  prelim?: StructuredReport | null;
  prelim_feedback?: Array<{
    prelim: string;
    feedback: 'accepted' | 'rejected';
  }>;
  studyReport?: string;
  onFeedbackUpdate?: (studyId: string, updatedFeedback: FeedbackRecord) => void;
}

interface Finding {
  condition: string;
  value: string | number;
  category: string;
  status: 'pending' | 'accepted' | 'rejected' | 'neutral';
}

// Define the SpatialGroupCard component
const SpatialGroupCard: React.FC<{
  group: SpatialGroup;
  onConditionSelect?: (condition: string | null, modelType: 'base' | 'experimental' | null) => void;
  selectedCondition: string | null;
  setViewerMetrics: React.Dispatch<React.SetStateAction<ViewerMetrics>>;
  setSelectedCondition: (condition: string | null) => void;
  modelPredictions: Record<string, any>;
  modelType: 'base' | 'experimental';
  setCurrentSlice: React.Dispatch<React.SetStateAction<number>>;
  totalSlices: number;
}> = ({
  group,
  onConditionSelect,
  selectedCondition,
  setViewerMetrics,
  setSelectedCondition,
  modelPredictions,
  modelType,
  setCurrentSlice,
  totalSlices,
}) => {
  const [isGroupBBoxVisible, setIsGroupBBoxVisible] = useState(false);

  const handleGroupHeaderClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    const isSelected = isGroupBBoxVisible;
    const groupBBoxKey = `group_bbox_${group.id}`;

    if (!isSelected) {
      setIsGroupBBoxVisible(true);
      setViewerMetrics((prev) => ({
        ...prev,
        showBoundingBoxes: true,
        selectedConditions: new Set([groupBBoxKey]),
      }));

      // Navigate to the middle slice of the group's range
      const targetSlice = Math.round((group.bbox.startSlice + group.bbox.endSlice) / 2);

      // Invert the slice index
      const invertedSlice = totalSlices - targetSlice - 1;
      setCurrentSlice(invertedSlice);
    } else {
      setIsGroupBBoxVisible(false);
      setViewerMetrics((prev) => ({
        ...prev,
        showBoundingBoxes: false,
        selectedConditions: new Set(),
      }));
    }
  };

  const handleFindingClick = (findingCondition: string) => {
    const isSelected = findingCondition === selectedCondition;

    if (!isSelected) {
      setSelectedCondition(findingCondition);
      setViewerMetrics((prev) => ({
        ...prev,
        showBoundingBoxes: true,
        selectedConditions: new Set([findingCondition]),
      }));

      // Navigate to the slice where the finding appears
      onConditionSelect?.(findingCondition, modelType);
    } else {
      setSelectedCondition(null);
      setViewerMetrics((prev) => ({
        ...prev,
        showBoundingBoxes: false,
        selectedConditions: new Set(),
      }));
      onConditionSelect?.(null, null);
    }
  };
  const isGroupSelected = group.findings.some((f) => f.condition === selectedCondition);

  return (
    <div
      className={`
        bg-gray-800/50 rounded-lg p-2 border
        ${isGroupSelected ? 'border-accent' : 'border-gray-700/30'}
        transition-colors
      `}
    >
      {/* Make only the header clickable for group bbox */}
      <div
        className="flex items-center justify-between mb-2 cursor-pointer hover:bg-gray-700/30 p-1 rounded"
        onClick={handleGroupHeaderClick}
      >
        <div className="text-xs text-gray-400">
          {group.id} - Location: ({Math.round(group.center.x)}, {Math.round(group.center.y)}, {Math.round(group.center.slice)})
        </div>
        <div className="text-xs font-medium text-primary">
          {group.findings.length} finding{group.findings.length > 1 ? 's' : ''}
        </div>
      </div>

      {/* Findings within the group */}
      <div className="space-y-1 max-h-48 overflow-y-auto pr-1">
        {group.findings.map((finding) => {
          const [organ, findingName] = finding.condition.split('/');
          const isSelected = finding.condition === selectedCondition;

          return (
            <div
              key={finding.condition}
              onClick={() => handleFindingClick(finding.condition)}
              className={`
                cursor-pointer
                ${isSelected ? 'shine-effect' : ''}
                transition-all duration-200 ease-in-out
                relative
                ${
                  finding.status === 'detected'
                    ? 'bg-gradient-to-r from-primary/10 to-primary/20 border-primary/30'
                    : 'bg-gradient-to-r from-accent/10 to-accent/20 border-accent/30'
                }
                rounded-lg
                hover:bg-opacity-90
                border border-transparent
                ${isSelected ? 'border-accent' : ''}
              `}
            >
              <div className="flex items-center justify-between py-1.5 px-3 relative z-10">
                <div className="flex flex-col">
                  <span className="text-[10px] text-gray-300/80 font-medium">
                    {formatOrganName(organ)}
                  </span>
                  <span className={`text-xs font-medium ${isSelected ? 'font-bold' : ''}`}>
                    {formatFindingName(findingName || '')}
                  </span>
                </div>
                {/* Finding status indicator */}
                <span
                  className={`
                    px-1.5 py-0.5 rounded-full text-[10px]
                    ${
                      finding.status === 'detected'
                        ? 'bg-primary/20 text-primary-light'
                        : 'bg-accent/20 text-accent-light'
                    }
                  `}
                >
                  {finding.status}
                </span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

// Helper function to organize findings by organ
const organizeByOrgan = (findings: Finding[]): Record<string, Finding[]> => {
  return findings
    .sort((a, b) => {
      const organA = a.condition.split('/')[0];
      const organB = b.condition.split('/')[0];
      const organCompare = organA.localeCompare(organB);
      if (organCompare !== 0) return organCompare;
      return (a.condition.split('/')[1] || '').localeCompare(
        b.condition.split('/')[1] || ''
      );
    })
    .reduce((acc: Record<string, Finding[]>, finding) => {
      const organ = finding.condition.split('/')[0];
      if (!acc[organ]) acc[organ] = [];
      acc[organ].push(finding);
      return acc;
    }, {});
};

// Add these interfaces at the top level
interface RenderFindingProps {
  findingItem: Finding;
  isSelected: boolean;
  modelPredictions: Record<string, any>;
  onConditionSelect: ((condition: string | null, modelType: 'base' | 'experimental' | null) => void) | undefined;
  setViewerMetrics: React.Dispatch<React.SetStateAction<ViewerMetrics>>;
  setSelectedCondition: (condition: string | null) => void;
  handleStatusChange: (condition: string, status: 'accepted' | 'rejected' | 'pending' | 'neutral') => Promise<void>;
  modelType: 'base' | 'experimental';
}

// Update the renderFinding function signature
const renderFinding = ({
  findingItem,
  isSelected,
  modelPredictions,
  onConditionSelect,
  setViewerMetrics,
  setSelectedCondition,
  handleStatusChange,
  modelType,
}: RenderFindingProps) => {
  const [organ, findingName] = findingItem.condition.split('/');
  const formattedOrgan = formatOrganName(organ);
  const formattedFindingName = formatFindingName(findingName || organ);
  const findingStatus = findingItem.status;

  const categoryClasses: Record<string, string> = {
    Likely: 'bg-gradient-to-r from-primary/10 to-primary/20 border-primary/30',
    Possible: 'bg-gradient-to-r from-accent/10 to-accent/20 border-accent/30',
  };

  const getStatusIcon = () => {
    switch (findingStatus) {
      case 'accepted':
        return <FaCheck className="w-3 h-3 text-green-500" />;
      case 'neutral':
        return <div className="w-3 h-3 rounded border-2 border-yellow-500" />;
      case 'rejected':
        return <div className="w-3 h-0.5 bg-red-500" />;
      default:
        return <div className="w-3 h-3 rounded-full border border-gray-500" />;
    }
  };

  const getStatusTooltip = () => {
    switch (findingStatus) {
      case 'accepted':
        return 'Confirmed';
      case 'neutral':
        return 'Uncertain';
      case 'rejected':
        return 'Incorrect';
      default:
        return 'Click to provide feedback';
    }
  };

  // Inside renderFinding function
  const handleClick = () => {

    if (!isSelected) {
      setSelectedCondition(findingItem.condition);
      setViewerMetrics((prev) => ({
        ...prev,
        showBoundingBoxes: true,
        selectedConditions: new Set([findingItem.condition]),
      }));
      onConditionSelect?.(findingItem.condition, modelType);
    } else {
      setSelectedCondition(null);
      setViewerMetrics((prev) => ({
        ...prev,
        showBoundingBoxes: false,
        selectedConditions: new Set(),
      }));
      onConditionSelect?.(null, null);
    }
  };

  const handleStatusClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    const statusCycle = {
      pending: 'accepted',
      accepted: 'neutral',
      neutral: 'rejected',
      rejected: 'pending',
    } as const;
    handleStatusChange(findingItem.condition, statusCycle[findingStatus]);
  };

  return (
    <div
      key={findingItem.condition}
      onClick={handleClick}
      className={`
        cursor-pointer
        ${isSelected ? 'shine-effect' : ''}
        transition-all duration-200 ease-in-out
        relative
        ${categoryClasses[findingItem.category] || ''}
        rounded-lg
        hover:bg-opacity-90
        border border-transparent
        ${isSelected ? 'border-accent' : ''}
      `}
    >
      <div className="flex items-center justify-between py-1.5 px-3 relative z-10">
        <div className="flex flex-col">
          <span className="text-[10px] text-gray-300/80 font-medium">{formattedOrgan}</span>
          <span className={`text-xs font-medium ${isSelected ? 'font-bold' : ''}`}>
            {formattedFindingName}
          </span>
        </div>

        {/* Right side - feedback button only */}
        <button
          className={`p-1 rounded-full transition-colors ${
            findingStatus === 'accepted'
              ? 'bg-primary text-white'
              : 'bg-gray-700/50 hover:bg-gray-600/50 text-gray-300 hover:text-white'
          }`}
          onClick={handleStatusClick}
          title={getStatusTooltip()}
        >
          {getStatusIcon()}
        </button>
      </div>
    </div>
  );
};

// Update the renderNotFoundFinding function signature
interface RenderNotFoundFindingProps {
  findingItem: Finding;
  handleStatusChange: (condition: string, status: 'accepted' | 'rejected' | 'pending' | 'neutral') => Promise<void>;
}

const renderNotFoundFinding = ({
  findingItem,
  handleStatusChange
}: RenderNotFoundFindingProps) => {
  const formattedCondition = formatConditionDisplay(findingItem.condition);
  const findingStatus = findingItem.status;

  const getStatusIcon = () => {
    switch (findingStatus) {
      case 'accepted':
        return <FaCheck className="w-3 h-3 text-green-500" />;
      case 'neutral':
        return <div className="w-3 h-3 rounded border-2 border-yellow-500" />;
      case 'rejected':
        return <div className="w-3 h-0.5 bg-red-500" />;
      default:
        return <div className="w-3 h-3 rounded-full border border-gray-500" />;
    }
  };

  const getStatusTooltip = () => {
    switch (findingStatus) {
      case 'accepted':
        return 'Confirmed';
      case 'neutral':
        return 'Uncertain';
      case 'rejected':
        return 'Incorrect';
      default:
        return 'Click to provide feedback';
    }
  };

  const handleClick = () => {
    const statusCycle = {
      pending: 'accepted',
      accepted: 'neutral',
      neutral: 'rejected',
      rejected: 'pending',
    } as const;
    handleStatusChange(findingItem.condition, statusCycle[findingStatus]);
  };

  return (
    <button
      key={findingItem.condition}
      onClick={handleClick}
      className="flex items-center justify-between w-full px-3 py-1.5 bg-gray-700/30 hover:bg-gray-700/50 rounded text-xs text-gray-300 transition-colors"
      title={getStatusTooltip()}
    >
      <span className="truncate">{formattedCondition}</span>
      <div className="flex items-center justify-center w-6 flex-shrink-0">{getStatusIcon()}</div>
    </button>
  );
};

// Update the FindingsSection component to pass the required props
const FindingsSection: React.FC<{
  title: string;
  findings: Finding[];
  predictions: Record<string, any>;
  selectedCondition: string | null;
  onConditionSelect?: (condition: string | null, modelType: 'base' | 'experimental' | null) => void;
  setViewerMetrics: React.Dispatch<React.SetStateAction<ViewerMetrics>>;
  setSelectedCondition: (condition: string | null) => void;
  handleStatusChange: (condition: string, status: 'accepted' | 'rejected' | 'pending' | 'neutral') => Promise<void>;
  modelType: 'base' | 'experimental';
  isExpanded: boolean;
  setIsExpanded: (value: boolean) => void;
}> = ({
  title,
  findings,
  predictions,
  selectedCondition,
  onConditionSelect,
  setViewerMetrics,
  setSelectedCondition,
  handleStatusChange,
  modelType,
  isExpanded,
  setIsExpanded,
}) => {
  const categories = ['Likely', 'Possible'];

  return (
    <div className="bg-gray-900/95 backdrop-blur-md border border-gray-800/50 rounded-lg overflow-hidden">
      <div
        className="p-2 bg-gray-800/50 border-b border-gray-700/50 flex items-center justify-between cursor-pointer"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <h3 className="text-xs font-medium flex items-center text-gray-300">
          <FaChevronRight className={`mr-2 transform transition-transform ${isExpanded ? 'rotate-90' : ''}`} />
          {title}
        </h3>
      </div>
      {isExpanded && (
        <div className="p-2">
          <div className="space-y-4">
            {categories.map((category) => {
              const categoryFindings = findings.filter(
                (item) => item.category === category
              );

              if (categoryFindings.length === 0) return null;

              // Map the category display text
              const displayCategory = category === 'Detected' ? 'LIKELY' :
                                    category === 'Suspected' ? 'POSSIBLE' :
                                    category;

              return (
                <div key={category}>
                  <div className="text-[10px] font-medium mb-1.5 text-gray-400 uppercase tracking-wide">
                    {displayCategory}
                  </div>
                  <div className="space-y-2.5">
                    {Object.entries(organizeByOrgan(categoryFindings)).map(
                      ([organ, organFindings]) => (
                        <div key={organ} className="space-y-1">
                          <div className="text-[10px] text-gray-500 uppercase tracking-wider mb-1">
                            {organ}
                          </div>
                          <div className="space-y-1">
                            {organFindings.map((item: Finding) =>
                              renderFinding({
                                findingItem: item,
                                isSelected: item.condition === selectedCondition,
                                modelPredictions: predictions,
                                onConditionSelect,
                                setViewerMetrics,
                                setSelectedCondition,
                                handleStatusChange,
                                modelType,
                              })
                            )}
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              );
            })}

            {/* Not Found */}
            {findings.some((item) => item.category === 'Not Found') && (
              <div>
                <div className="text-xs font-medium mb-2 text-white">Not Found</div>
                <div className="space-y-4">
                  {Object.entries(
                    organizeByOrgan(
                      findings.filter((item) => item.category === 'Not Found')
                    )
                  ).map(([organ, organFindings]) => (
                    <div key={organ} className="space-y-1">
                      <div className="text-xs text-gray-400 uppercase tracking-wider">
                        {organ}
                      </div>
                      <div className="space-y-0.5">
                        {(organFindings as Finding[]).map((item: Finding) =>
                          renderNotFoundFinding({
                            findingItem: item,
                            handleStatusChange
                          })
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

// Define a new component for report findings
const ReportFindingItem: React.FC<{
  finding: GroupedFinding;
  isSelected: boolean;
  onClick: () => void;
}> = ({ finding, isSelected, onClick }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedText, setEditedText] = useState(finding.text);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (isEditing && textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      textareaRef.current.focus();
    }
  }, [isEditing]);

  const handleDoubleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsEditing(true);
  };

  const handleBlur = () => {
    setIsEditing(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleBlur();
    }
  };

  return (
    <div
      onClick={(e) => {
        if (!isEditing) {
          e.preventDefault();
          onClick();
        }
      }}
      onDoubleClick={handleDoubleClick}
      className={`report-finding ${isSelected ? 'selected' : ''}`}
    >
      {isEditing ? (
        <textarea
          ref={textareaRef}
          value={editedText}
          onChange={(e) => setEditedText(e.target.value)}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          className="report-finding-textarea"
          rows={1}
        />
      ) : (
        <p className="report-finding-text">{editedText}</p>
      )}
    </div>
  );
};

const PredictionsDisplay: React.FC<PredictionsDisplayProps> = ({
  studyId,
  feedback: initialFeedback,
  onConditionSelect,
  setViewerMetrics,
  viewerMetrics,
  report,
  isGeneratingReport,
  onRegenerateReport,
  baseModelPredictions,
  experimentalModelPredictions,
  spatialGroups,
  setCurrentSlice,
  totalSlices,
  onGroupSelect,
  activeGroupId,
  prelim,
  prelim_feedback,
  studyReport,
  onFeedbackUpdate
}) => {
  const [selectedCondition, setSelectedCondition] = useState<string | null>(null);
  const [selectedFinding, setSelectedFinding] = useState<number | null>(null);
  const { token, userId } = useAuth();

  useEffect(() => {
    // First check if prelim exists and has findings
    if (prelim?.findings?.length) {
      const initialStatus: Record<string, 'accepted' | 'rejected' | 'pending'> = {};

      prelim.findings.forEach((_, index) => {
        // Initialize all findings as pending
        initialStatus[index] = 'pending';

        // If there's existing feedback, use it
        const feedback = prelim_feedback?.[index];
        if (feedback) {
          initialStatus[index] = feedback.feedback;
        }
      });

      setReportStatus(initialStatus);

      // Only lock if we have valid feedback for all findings
      const hasValidFeedback = prelim_feedback &&
        prelim_feedback.length === prelim.findings.length &&
        prelim_feedback.every(f => f.feedback === 'accepted' || f.feedback === 'rejected');

      setIsLocked(!!hasValidFeedback);
    } else {
      // Reset states when no findings are present
      setReportStatus({});
      setIsLocked(false);
    }
  }, [prelim, prelim_feedback]);

  // State for collapsible sections
  const [isReportExpanded, setIsReportExpanded] = useState(false);
  const [isPrelimExpanded, setIsPrelimExpanded] = useState(false);
  const [isSpatialGroupsExpanded, setIsSpatialGroupsExpanded] = useState(false);
  const [isBaseModelExpanded, setIsBaseModelExpanded] = useState(false);
  const [isExperimentalModelExpanded, setIsExperimentalModelExpanded] = useState(true);
  const [feedback, setFeedback] = useState<FeedbackRecord | undefined>(initialFeedback);

  // Add new state for tracking accepted/rejected findings
  const [reportStatus, setReportStatus] = useState<Record<string, 'accepted' | 'rejected' | 'pending'>>({});
  const [isLocked, setIsLocked] = useState(false);

  const [isReportTabExpanded, setIsReportTabExpanded] = useState(false);

  // Add new handler functions
  const handleAcceptFinding = (index: number) => {
    if (isLocked) return;
    setReportStatus(prev => ({
      ...prev,
      [index]: 'accepted'
    }));
  };

  const handleRejectFinding = (index: number) => {
    if (isLocked) return;
    setReportStatus(prev => ({
      ...prev,
      [index]: 'rejected'
    }));
  };

  const handleLockReport = async () => {
    if (isLocked || !report || !token || !userId || !studyId) {
      console.error('Missing required data for sending prelims');
      return;
    }

    // Get all findings that don't have explicit feedback
    const findingsWithoutFeedback = report.findings.filter((_, index) => {
      const status = reportStatus[index];
      return !status || status === 'pending';
    });

    if (findingsWithoutFeedback.length > 0) {
      alert(`Please provide feedback for all findings before submitting.\n\n${findingsWithoutFeedback.length} finding(s) still need feedback.`);
      return;
    }

    try {
      // Prepare the prelims data with feedback
      const prelimsWithFeedback = report.findings.map((finding, index) => ({
        prelim: finding.text,
        feedback: reportStatus[index]
      }));

      // Send to API
      await axios.post(
        `${API_BASE_URL}/prelim_feedback`,
        {
          user_id: userId,
          study_id: studyId,
          prelim_feedback: prelimsWithFeedback,
          prelim: report,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Ocp-Apim-Subscription-Key': 'replace_with_subscription_key'
          }
        }
      );

      // Filter only accepted findings for clipboard
      const acceptedFindings = report.findings.filter((_, index) =>
        reportStatus[index] === 'accepted'
      );

      // Create text for clipboard
      const reportText = acceptedFindings
        .map(finding => finding.text)
        .join('\n');

      // Copy to clipboard
      await navigator.clipboard.writeText(reportText);
      setIsLocked(true);
    } catch (error) {
      console.error('Error sending prelims:', error);
      alert('Failed to submit report. Please try again.');
    }
  };

    // Initialize report status with prelim findings if available
    useEffect(() => {
      if (prelim?.findings) {
        const initialStatus: Record<string, 'accepted' | 'rejected' | 'pending'> = {};
        prelim.findings.forEach((_, index) => {
          initialStatus[index] = 'pending';  // Initialize all findings as pending instead of accepted
        });
        setReportStatus(initialStatus);
      }
    }, [prelim]);

    // Add useEffect to fetch feedback on mount
    useEffect(() => {
      const fetchFeedback = async () => {
        if (!token || !userId || !studyId) {
          console.error('Missing required authentication or study information');
          return;
        }

        try {
          const response = await axios.post(
            `${API_BASE_URL}/get_feedback`,
            {
              study_id: studyId,
              user_id: userId
            },
            {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Ocp-Apim-Subscription-Key': 'replace_with_subscription_key'
              }
            }
          );

          if (response.data.feedback) {
            const feedbackData = response.data.feedback as FeedbackRecord;

            // Update feedback state
            setFeedback(feedbackData);

            // Update findings with feedback data
            const updateFindingsWithFeedback = (findings: Finding[]) => {
              return findings.map(finding => {
                const feedbackInfo = feedbackData[finding.condition];
                if (feedbackInfo) {
                  const [status] = feedbackInfo;
                  return {
                    ...finding,
                    status: status as 'pending' | 'accepted' | 'rejected' | 'neutral'
                  };
                }
                return finding;
              });
            };

            // Update both base and experimental findings
            setBaseModelFindings(prevFindings =>
              updateFindingsWithFeedback(prevFindings)
            );

            setExperimentalFindings(prevFindings =>
              updateFindingsWithFeedback(prevFindings)
            );
          }
        } catch (error) {
          console.error('Error fetching feedback:', error);
        }
      };

      fetchFeedback();
    }, [studyId, userId, token]);

  const getCategory = (
    condition: string,
    modelPredictions: Record<string, any>
  ): string => {
    const classKey = `class_${condition}`;
    const classValue = modelPredictions[classKey];

    if (typeof classValue === 'string') {
      if (classValue === 'detected') return 'Likely';
      if (classValue === 'suspected') return 'Possible';
      return 'Not Found';
    }
    return 'Not Found';
  };

  // Process findings for both models
  const processFindings = (modelPredictions: Record<string, any>) => {
    return Object.entries(modelPredictions)
      .filter(([key]) => !key.startsWith('class_') && !key.startsWith('bbox_'))
      .map(([condition, value]) => {
        const category = getCategory(condition, modelPredictions);
        const feedbackInfo = feedback?.[condition];

        // Only apply feedback status if the category matches the stored feedback category
        let status: 'pending' | 'accepted' | 'rejected' | 'neutral' = 'pending';
        if (feedbackInfo) {
          const [feedbackStatus, _, feedbackCategory] = feedbackInfo;
          // Convert model category to match feedback category format
          const modelCategory = category.toLowerCase();
          if (feedbackCategory.toLowerCase() === modelPredictions[`class_${condition}`].toLowerCase()) {
            status = feedbackStatus as 'pending' | 'accepted' | 'rejected' | 'neutral';
          }
        }

        return {
          condition,
          value,
          category,
          status,
        };
      })
      .sort((a, b) => {
        const categoryOrder: Record<string, number> = {
          Likely: 0,
          Possible: 1,
          'Not Found': 2,
        };
        if (categoryOrder[a.category] !== categoryOrder[b.category]) {
          return categoryOrder[a.category] - categoryOrder[b.category];
        }
        if (typeof a.value === 'number' && typeof b.value === 'number') {
          return (b.value as number) - (a.value as number);
        }
        return 0;
      });
  };

  const [baseModelFindings, setBaseModelFindings] = useState(processFindings(baseModelPredictions));
  const [experimentalModelFindings, setExperimentalFindings] = useState(processFindings(experimentalModelPredictions));
  useEffect(() => {
    // Update findings when predictions change
    setBaseModelFindings(processFindings(baseModelPredictions));
    setExperimentalFindings(processFindings(experimentalModelPredictions));
  }, [baseModelPredictions, experimentalModelPredictions, feedback]);

  const handleStatusChange = async (
    condition: string,
    newStatus: 'accepted' | 'rejected' | 'pending' | 'neutral'
  ) => {
    if (!token || !userId) {
      console.error('No authentication available');
      return;
    }

    try {
      await axios.post(
        `${API_BASE_URL}/feedback`,
        {
          study_id: studyId,
          user_id: userId,
          condition: condition,
          status: newStatus,
          probability: experimentalModelPredictions[condition],
          class_value: experimentalModelPredictions[`class_${condition}`],
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            'Ocp-Apim-Subscription-Key': 'replace_with_subscription_key',
          },
        }
      );

    // Update the findings arrays with the new feedback status
    const updateFindingsStatus = (findings: Finding[]) => {
      return findings.map(finding =>
        finding.condition === condition
          ? { ...finding, status: newStatus }
          : finding
      );
    };

    // Update base model findings
    const updatedBaseFindings = updateFindingsStatus(baseModelFindings);
    setBaseModelFindings(updatedBaseFindings);

    // Update experimental model findings
    const updatedExperimentalFindings = updateFindingsStatus(experimentalModelFindings);
    setExperimentalFindings(updatedExperimentalFindings);

    // Update the feedback state
    if (feedback) {
      const updatedFeedback: FeedbackRecord = {
        ...feedback,
        [condition]: [
          newStatus,
          Number(experimentalModelPredictions[condition]),
          experimentalModelPredictions[`class_${condition}`],
        ],
      };
      // Trigger a re-render with the updated feedback
      setFeedback(updatedFeedback);
      // Notify parent component of the feedback update
      onFeedbackUpdate?.(studyId, updatedFeedback);
    }
  } catch (error) {
    console.error('Error sending feedback:', error);
  }
  };

  // Add this new state variable
  const [showMiscSections, setShowMiscSections] = useState(false);

  const displayReport = report || prelim;

  // Inside PredictionsDisplay component
  const handleGroupSelect = (finding: GroupedFinding, index: number) => {
    if (!finding.group_id || finding.group_id === 'none') {
      // Clear any active bounding boxes/hotspots
      setSelectedFinding(null);
      onGroupSelect?.(null);
      setViewerMetrics(prev => ({
        ...prev,
        showBoundingBoxes: false,
        selectedConditions: new Set(),
      }));
    } else {
      setSelectedFinding(index);
      onGroupSelect?.(finding.group_id);
    }
  };

  return (
    <div className="h-full flex flex-col overflow-y-auto">
      <div className="flex justify-end p-2">
        <button
          onClick={() => setShowMiscSections(!showMiscSections)}
          className="p-2 bg-gray-800 rounded-md hover:bg-gray-700 transition-colors"
          title="Toggle Misc Sections"
        >
          <FaEllipsisV className="w-5 h-5 text-gray-400 hover:text-white" />
        </button>
      </div>

      <div className="flex flex-col space-y-2 p-2">
        {/* Report Section */}
        {showMiscSections && studyReport && (
          <div className="bg-gray-900/95 backdrop-blur-md border border-gray-800/50 rounded-lg overflow-hidden">
            <div
              className="p-2 bg-gray-800/50 border-b border-gray-700/50 flex items-center justify-between cursor-pointer"
              onClick={() => setIsReportExpanded(!isReportExpanded)}
            >
              <h3 className="text-xs font-medium flex items-center text-gray-300">
                <FaChevronRight
                  className={`mr-2 transform transition-transform ${
                    isReportExpanded ? 'rotate-90' : ''
                  }`}
                />
                Report
              </h3>
            </div>
            {isReportExpanded && (
              <div className="p-4">
                <div className="text-sm text-gray-300 whitespace-pre-wrap">
                  {studyReport}
                </div>
              </div>
            )}
          </div>
        )}

        {showMiscSections && (
          <div className="bg-gray-900/95 backdrop-blur-md border border-gray-800/50 rounded-lg overflow-hidden">
            <div
              className="p-2 bg-gray-800/50 border-b border-gray-700/50 flex items-center justify-between cursor-pointer"
              onClick={() => setIsPrelimExpanded(!isPrelimExpanded)}
            >
              <h3 className="text-xs font-medium flex items-center text-gray-300">
                <FaChevronRight
                  className={`mr-2 transform transition-transform ${
                    isPrelimExpanded ? 'rotate-90' : ''
                  }`}
                />
                a2z Radiology AI Instant Prelims
              </h3>
              <div className="flex items-center gap-2">
                {!isLocked && (
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setReportStatus({});
                      setSelectedFinding(null);
                      onRegenerateReport?.();
                    }}
                    disabled={isGeneratingReport}
                    className={`p-1 rounded-md transition-all duration-200 ${
                      isGeneratingReport
                        ? 'bg-gray-700/30 text-gray-500 cursor-not-allowed'
                        : 'bg-gray-700/50 hover:bg-gray-600/50 text-gray-400 hover:text-white'
                    }`}
                  >
                    <FaRedo className={`w-3 h-3 ${isGeneratingReport ? 'animate-spin' : ''}`} />
                  </button>
                )}
              </div>
            </div>

            {isPrelimExpanded && (
              <div className="p-3">
                {isGeneratingReport ? (
                  <div className="flex items-center justify-center py-2">
                    <FaSpinner className="animate-spin text-primary mr-2 w-3 h-3" />
                    <span className="text-xs text-gray-400">Generating report...</span>
                  </div>
                ) : displayReport ? (
                  <div className="space-y-3">
                    {displayReport.findings.map((finding, index) => (
                      <div
                        key={index}
                        className={`p-3 rounded transition-all duration-200
                          ${!finding.group_id || finding.group_id === 'none' ? 'cursor-default' : 'cursor-pointer'}
                          ${
                            selectedFinding === index
                              ? 'bg-accent/20 border border-accent text-gray-100'
                              : 'bg-gray-700/20 border border-gray-700/10 hover:bg-gray-700/30'
                          }
                          ${reportStatus[index] === 'accepted' ? 'bg-primary/10 border-primary/20' : ''}
                          ${reportStatus[index] === 'rejected' ? 'hidden' : ''}
                          ${isLocked ? 'opacity-50' : ''}
                        `}
                        onClick={() => {
                          if (!finding.group_id || finding.group_id === 'none') {
                            return;
                          }
                          if (selectedFinding === index) {
                            setSelectedFinding(null);
                            onGroupSelect?.(null);
                          } else {
                            handleGroupSelect(finding, index);
                          }
                        }}
                      >
                        <div className="flex items-start justify-between gap-2">
                          <div className={`flex-1 text-sm leading-snug ${
                            selectedFinding === index ? 'font-semibold' : 'text-gray-300'
                          }`}>
                            {finding.text}
                          </div>
                          {!isLocked && (
                            <div className="flex items-center gap-1 mt-1">
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleAcceptFinding(index);
                                }}
                                className={`p-1.5 rounded-lg transition-all duration-200
                                  ${
                                    reportStatus[index] === 'accepted'
                                      ? 'bg-primary/20 text-primary-light'
                                      : 'bg-gray-700/30 hover:bg-gray-600/30 text-gray-400 hover:text-primary-light'
                                  }
                                `}
                                title="Accept finding"
                              >
                                <FaCheck className="w-3 h-3" />
                              </button>
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleRejectFinding(index);
                                }}
                                className="p-1.5 rounded-lg bg-gray-700/30 hover:bg-gray-600/30 text-gray-400 hover:text-red-400 transition-all duration-200"
                                title="Remove finding"
                              >
                                <FaTimes className="w-3 h-3" />
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}

                    <button
                      onClick={handleLockReport}
                      disabled={isLocked}
                      className={`
                        w-full mt-4 p-2 rounded-lg
                        flex items-center justify-center gap-2
                        transition-all duration-200
                        ${isLocked
                          ? 'bg-gray-700/30 text-gray-500 cursor-not-allowed'
                          : 'bg-primary/20 hover:bg-primary/30 text-primary-light hover:translate-y-[-1px]'}
                      `}
                      title={isLocked ? 'Report already submitted' : 'Send report and copy to clipboard'}
                    >
                      <span className="text-sm font-medium">
                        {isLocked ? 'Report Submitted' : 'Send Report'}
                      </span>
                      {isLocked ? <FaLock className="w-3 h-3" /> : <FaPaperPlane className="w-3 h-3" />}
                    </button>
                  </div>
                ) : (
                  <div className="text-sm text-gray-400 text-center py-4">
                    No report available
                  </div>
                )}
              </div>
            )}
          </div>
        )}

        {/* Spatial Groups Section (Hotspots) */}
        {showMiscSections && (
          <div className="bg-gray-900/95 backdrop-blur-md border border-gray-800/50 rounded-lg overflow-hidden">
            <div
              className="p-2 bg-gray-800/50 border-b border-gray-700/50 flex items-center justify-between cursor-pointer"
              onClick={() => setIsSpatialGroupsExpanded(!isSpatialGroupsExpanded)}
            >
              <h3 className="text-xs font-medium flex items-center text-gray-300">
                <FaChevronRight className={`mr-2 transform transition-transform ${isSpatialGroupsExpanded ? 'rotate-90' : ''}`} />
                Hotspots
              </h3>
              <span className="text-[11px] text-gray-400">
                {spatialGroups.length} groups
              </span>
            </div>
            {isSpatialGroupsExpanded && (
              <div className="spatial-groups-content">
                <div className="p-2">
                  <div className="space-y-2">
                    {spatialGroups.map((group) => (
                      <SpatialGroupCard
                        key={group.id}
                        group={group}
                        onConditionSelect={onConditionSelect}
                        selectedCondition={selectedCondition}
                        setViewerMetrics={setViewerMetrics}
                        setSelectedCondition={setSelectedCondition}
                        modelPredictions={experimentalModelPredictions}
                        modelType="experimental"
                        setCurrentSlice={setCurrentSlice}
                        totalSlices={totalSlices}
                      />
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        {/* Findings sections */}
        <div className="flex flex-col space-y-2">
          <FindingsSection
            title="Findings (a2z-2)"
            findings={experimentalModelFindings}
            predictions={experimentalModelPredictions}
            selectedCondition={selectedCondition}
            onConditionSelect={onConditionSelect}
            setViewerMetrics={setViewerMetrics}
            setSelectedCondition={setSelectedCondition}
            handleStatusChange={handleStatusChange}
            modelType="experimental"
            isExpanded={isExperimentalModelExpanded}
            setIsExpanded={setIsExperimentalModelExpanded}
          />
          {/* Findings (a2z-1) section
          {showMiscSections && (
            <FindingsSection
              title="Findings (a2z-1)"
              findings={baseModelFindings}
              predictions={baseModelPredictions}
              selectedCondition={selectedCondition}
              onConditionSelect={onConditionSelect}
              setViewerMetrics={setViewerMetrics}
              setSelectedCondition={setSelectedCondition}
              handleStatusChange={handleStatusChange}
              modelType="base"
              isExpanded={isBaseModelExpanded}
              setIsExpanded={setIsBaseModelExpanded}
            />
          )} */}
        </div>
      </div>
    </div>
  );
};

export default PredictionsDisplay;