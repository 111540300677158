import { API_BASE_URL } from '../config/api';
const downloadCSV = (csvString: string, filename: string) => {
  const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);

  link.setAttribute('href', url);
  link.setAttribute('download', filename);
  link.style.visibility = 'hidden';

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url); // Clean up the URL object
};

export const handleCSVDownload = async (userId: string, token: string) => {
  try {
    const response = await fetch(`${API_BASE_URL}/get_user_csv`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ user_id: userId })
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();

    if (data.status === 200) {
      // Replace terms in both CSV strings
      const processedA2z1 = data.a2z1_csv
        .replace(/suspected/g, "possible")
        .replace(/detected/g, "likely");
      const processedA2z2 = data.a2z2_csv
        .replace(/suspected/g, "possible")
        .replace(/detected/g, "likely");

      // downloadCSV(processedA2z1, `a2z1_results_${userId}.csv`);
      downloadCSV(processedA2z2, `a2z2_results_${userId}.csv`);
      return true;
    } else {
      throw new Error(data.message || 'Failed to download CSV');
    }
  } catch (error) {
    console.error('Error downloading CSV:', error);
    throw error;
  }
};
