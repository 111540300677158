import { StructuredReport } from '../utils/reportGenerator';

export interface DicomMetadata {
  [key: string]: DicomValue;
}
export type DicomValue = string | number | DicomMetadata | Array<string | number>;


export interface StudyData {
  studyId: string;
  isLoaded?: boolean;
  series: NpzData[];
  isLoading: boolean;
  base_model_prediction: Record<string, any>;
  experimental_model_prediction: Record<string, any>;
  seriesUrls: Record<string, string[]>;
  selectedSeriesId?: string;
  modelSelectedSeriesId?: string;
  feedback?: Record<string, [string, number, string]>;
  prelim?: StructuredReport;
  prelim_feedback?: Array<{
    prelim: string;
    feedback: 'accepted' | 'rejected';
  }> | null;
  report: string;
  metadata: Record<string, Record<string, any>>;  // Add this line
}

export interface ViewerMetrics {
  zoom: number;
  pan: { x: number; y: number };
  showHUValue: boolean;
  showCrosshair: boolean;
  showMeasurements: boolean;
  showBoundingBoxes: boolean;
  selectedConditions: Set<string>;
  selectedModel: 'base' | 'experimental' | null;
  currentSlice?: number;
  selectedGroup: string | null;
}

export type FeedbackRecord = Record<string, [string, number, string]>;

export enum ViewerMode {
  Default = 'default',
  Distance = 'distance',
}

export interface NpzData {
  data: Int16Array[][];
  dims: [number, number, number];
  filename: string;
  studyId: string;
  predictions: Record<string, string>;
}

export interface GroupBBox {
  minX: number;
  maxX: number;
  minY: number;
  maxY: number;
  startSlice: number;
  endSlice: number;
}

export interface BoundingBox {
  xmin: number;
  ymin: number;
  xmax: number;
  ymax: number;
}

export interface SliceBoundingBox {
  slice_idx: number;
  slice_importance: number;
  bbox: BoundingBox;
}


export interface UseSeriesLoaderProps {
  study: StudyData;
  setStudy: React.Dispatch<React.SetStateAction<StudyData>>;
  userId: string;
  npzWorker: Worker;
}