// src/components/AddUser.tsx
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { FaSpinner } from 'react-icons/fa';
import axios from 'axios';
import { API_BASE_URL } from '../config/api';

interface LocationState {
    token?: string;
    adminUserId?: string;
    fromSwitch?: boolean;
  }

const AddUser: React.FC = () => {
  const [formData, setFormData] = useState({
    new_user_id: '',
    new_user_password: '',
    new_user_type: 'client',
    new_user_subscription_key: '',
    new_user_email: '',
    new_parent_user_id: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
//   const { userId, token } = useAuth();
  const navigate = useNavigate();
  const auth = useAuth();
  const { token: authToken, userId } = auth;
  const location = useLocation() as { state: LocationState };  // Type assertion here
  const { token: routeToken, adminUserId, fromSwitch } = location.state || {};
  useEffect(() => {
    // If coming from switch user flow, update auth context
    if (fromSwitch && routeToken && adminUserId) {
      auth.setAuthState({
        isAuthenticated: true,
        token: routeToken,
        userId: adminUserId,
        isAdmin: true,
        adminUserId
      });
    } else if (!auth.isAuthenticated) {
        console.log('Not authenticated redirecting...')
      navigate('/login');
    }
  }, [fromSwitch, routeToken, adminUserId, auth, navigate]);


  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    try {
      const response = await axios.post(
        `${API_BASE_URL}/add_user`,
        {
          user_id: userId,
          ...formData
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${routeToken}`,
            "Ocp-Apim-Subscription-Key": "replace_with_subscription_key"
          }
        }
      );

      if (response.data.status === 200) {
        navigate('/login', {
          state: {
            fromSwitch: true,
            routeToken,
            adminUserId: userId
          }
        });
      } else {
        setError(response.data.message);
      }
    } catch (error: any) {
      setError(error.response?.data?.message || 'Failed to add user. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex min-h-screen bg-gray-900 text-white">
      <div className="flex-1 flex items-center justify-center p-4">
        <form
          onSubmit={handleSubmit}
          className="w-full max-w-md bg-gray-800/50 backdrop-blur-md border border-gray-700/50 rounded-lg p-8 shadow-2xl"
        >
          <div className="text-center mb-8">
            <h2 className="text-xl font-medium text-gray-300">Add New User</h2>
          </div>

          {error && (
            <div className="mb-6 p-3 bg-red-500/10 border border-red-500/50 rounded-lg">
              <p className="text-sm text-red-400">{error}</p>
            </div>
          )}

          <div className="space-y-6">
            <div>
              <label className="text-sm font-medium text-gray-400 mb-1 block">
                New User ID
              </label>
              <input
                type="text"
                name="new_user_id"
                value={formData.new_user_id}
                onChange={handleInputChange}
                className="w-full bg-gray-900/50 border border-gray-700 rounded-lg px-3 py-2
                  text-sm text-white placeholder-gray-500
                  focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary
                  transition-colors"
                required
              />
            </div>

            <div>
              <label className="text-sm font-medium text-gray-400 mb-1 block">
                Password
              </label>
              <input
                type="password"
                name="new_user_password"
                value={formData.new_user_password}
                onChange={handleInputChange}
                className="w-full bg-gray-900/50 border border-gray-700 rounded-lg px-3 py-2
                  text-sm text-white placeholder-gray-500
                  focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary
                  transition-colors"
                required
              />
            </div>

            <div>
              <label className="text-sm font-medium text-gray-400 mb-1 block">
                User Type
              </label>
              <select
                name="new_user_type"
                value={formData.new_user_type}
                onChange={handleInputChange}
                className="w-full bg-gray-900/50 border border-gray-700 rounded-lg px-3 py-2
                  text-sm text-white placeholder-gray-500
                  focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary
                  transition-colors"
                required
              >
                <option value="client">Client</option>
                <option value="admin">Admin</option>
              </select>
            </div>

            <div>
              <label className="text-sm font-medium text-gray-400 mb-1 block">
                Subscription Key (Optional)
              </label>
              <input
                type="text"
                name="new_user_subscription_key"
                value={formData.new_user_subscription_key}
                onChange={handleInputChange}
                className="w-full bg-gray-900/50 border border-gray-700 rounded-lg px-3 py-2
                  text-sm text-white placeholder-gray-500
                  focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary
                  transition-colors"
              />
            </div>
            <div>
              <label className="text-sm font-medium text-gray-400 mb-1 block">
                Parent User ID (Optional)
              </label>
              <input
                type="text"
                name="new_parent_user_id"
                value={formData.new_parent_user_id}
                onChange={handleInputChange}
                className="w-full bg-gray-900/50 border border-gray-700 rounded-lg px-3 py-2
                  text-sm text-white placeholder-gray-500
                  focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary
                  transition-colors"
              />
            </div>
            <div>
              <label className="text-sm font-medium text-gray-400 mb-1 block">
                Email (Optional)
              </label>
              <input
                type="email"
                name="new_user_email"
                value={formData.new_user_email}
                onChange={handleInputChange}
                className="w-full bg-gray-900/50 border border-gray-700 rounded-lg px-3 py-2
                  text-sm text-white placeholder-gray-500
                  focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary
                  transition-colors"
              />
            </div>
            <button
              type="submit"
              disabled={isLoading}
              className="w-full bg-gradient-to-r from-primary/80 to-primary
                hover:from-primary hover:to-primary-light
                text-white font-medium py-2 rounded-lg
                focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 focus:ring-offset-gray-900
                transition-all duration-200 ease-in-out
                disabled:opacity-50 disabled:cursor-not-allowed
                flex items-center justify-center"
            >
              {isLoading ? (
                <>
                  <FaSpinner className="animate-spin mr-2 h-4 w-4" />
                  <span>Adding user...</span>
                </>
              ) : (
                <span>Add User</span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddUser;